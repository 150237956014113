<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#437ccc" right timeout="3000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap pb-3>
      <v-flex xs6>
        <h3 class="bold">DIVISIONS</h3>
      </v-flex>
      <v-flex xs6 text-right>
        <v-btn color="primary" dark dense @click="myDialog = true">
          Add Division <v-icon x-small>mdi-plus</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout wrap v-if="allData.length > 0">
      <v-flex xs12 sm4 md3 pa-1 v-for="(item, i) in allData" :key="i">
        <v-card elevation="4">
          <v-layout wrap pa-3 style="font-family:pregular">
            <v-flex xs6  >
              <h4>{{ item.divisionName }}</h4>
            </v-flex>
            <v-flex xs6 text-right align-self-center pr-2>
              <v-btn
                x-small
                outlined
                :to="'/range?id=' + item._id + '&name=' + item.divisionName"
              >
                view range</v-btn
              >
            </v-flex>
            <v-flex xs12 pt-1>
              <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 text-right pt-1>
              <!-- <v-flex xs12 py-3 v-if="applc.resume">
                <span
                  v-if="
                    applc.resume.split('.').pop() == 'pdf' ||
                    applc.resume.split('.').pop() == 'PDF' ||
                    applc.resume.split('.').pop() == 'xlsx' ||
                    applc.resume.split('.').pop() == 'doc' ||
                    applc.resume.split('.').pop() == 'docx'
                  "
                >
                  <a :href="baseURL + '/i/' + applc.resume">
                    <v-btn dark color="blue" small> View </v-btn></a
                  ></span
                >
                <span v-else>
                  <v-btn
                    @click="(doc = applc.resume), (dialog = true)"
                    dark
                    color="blue"
                    small
                  >
                    View
                  </v-btn>
                </span>
              </v-flex> -->
              <template v-if="item.kmlFile.length > 0">
                <template
                  v-if="
                    item.kmlFile[0].split('.').pop() == 'pdf' ||
                    item.kmlFile[0].split('.').pop() == 'PDF' ||
                    item.kmlFile[0].split('.').pop() == 'xlsx' ||
                    item.kmlFile[0].split('.').pop() == 'doc' ||
                    item.kmlFile[0].split('.').pop() == 'docx'
                  "
                >
                  <a
                    :href="mediaURL + item.kmlFile"
                    style="text-decoration: none"
                  >
                    <v-btn dark color="blue" x-small text> View KML </v-btn></a
                  >
                </template>
                <template v-else>
                  <v-btn
                    @click="(imagedata = item.kmlFile[0]), (dialog = true)"
                    dark
                    color="blue"
                    x-small
                    text
                  >
                    View KML
                  </v-btn>
                </template>
              </template>

              <v-btn
                dark
                text
                color="error"
                x-small
                @click="(deleteDialog = true), (deleteId = item._id)"
              >
                delete
              </v-btn>
              <v-btn
                class="ml-1"
                text
                color="primary"
                x-small
                @click="
                  (editDialog = true),
                    ((Editdivision = item.divisionName), (editId = item._id))
                "
              >
                edit
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap v-else>
      <v-flex xs12 text-center>
        <h4>No Data Available......</h4>
      </v-flex>
    </v-layout>
    <!-- <v-layout wrap pt-2>
      <v-flex xs12>
        <v-pagination v-model="page" :length="pagelength"></v-pagination>
      </v-flex>
    </v-layout> -->

    <v-dialog width="500px" v-model="deleteDialog">
      <v-card width="500px">
        <v-layout wrap pa-5>
          <v-flex xs12>
            <h4>Are you sure you want to remove this Division</h4>
          </v-flex>
          <v-flex xs12 text-right>
            <v-btn x-small text color="error" @click="deleteDialog = false">
              close</v-btn
            >
            <v-btn
              class="ml-1"
              text
              x-small
              color="primary"
              @click="deleteDivision()"
            >
              remove
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog width="500px" v-model="myDialog">
      <v-card width="500px">
        <v-layout wrap pa-5>
          <v-flex xs12>
            <!-- {{ documentFile1 }},,,,,,, -->
            <v-text-field
              label="Division Name"
              outlined
              dense
              v-model="division"
            >
            </v-text-field>
          </v-flex>
          <v-flex xs12 text-center>
            <span class="popbold">Click here to KML Document</span>
            &nbsp;
            <input
              v-show="false"
              id="file1"
              ref="files"
              type="file"
              @change="browseCover"
            />
          </v-flex>
          <v-flex xs12 text-center>
            <v-icon x-large color="#408BFF" @click="$refs.files.click()"
              >mdi-cloud-upload</v-icon
            >
          </v-flex>
          <v-flex xs12 text-center v-if="documentFile1 != null">
            <v-chip
              class="ma-2"
              close
              color="green"
              outlined
              @click:close="documentFile1 = null"
            >
              {{ documentFile1.name }}
            </v-chip>
          </v-flex>

          <v-flex xs12 text-right>
            <v-btn x-small color="primary" @click="addDivision()"> Save </v-btn>
            <v-btn class="ml-1" x-small color="error" @click="myDialog = false">
              close</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog width="500px" v-model="editDialog">
      <v-card width="500px">
        <v-layout wrap pa-5>
          <v-flex xs12>
            <v-text-field
              label="Division Name"
              outlined
              dense
              v-model="Editdivision"
            >
            </v-text-field>
          </v-flex>
           <v-flex xs12 text-center>
            <span class="popbold">Click here to KML Document</span>
            &nbsp;
            <input
              v-show="false"
              id="file1"
              ref="files"
              type="file"
              @change="browseCover"
            />
          </v-flex>
          <v-flex xs12 text-center>
            <v-icon x-large color="#408BFF" @click="$refs.files.click()"
              >mdi-cloud-upload</v-icon
            >
          </v-flex>
          <v-flex xs12 text-center v-if="documentFile1 != null">
            <v-chip
              class="ma-2"
              close
              color="green"
              outlined
              @click:close="documentFile1 = null"
            >
              {{ documentFile1.name }}
            </v-chip>
          </v-flex>

          <v-flex xs12 text-right>
            <v-btn x-small color="error" @click="editDialog = false">
              close</v-btn
            >
            <v-btn
              class="ml-1"
              x-small
              color="primary"
              @click="EditDivisions()"
            >
              Save
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="dialog" width="70%">
      <v-card>
        <v-toolbar height="30px" dense dark >
          <v-spacer></v-spacer>
          <v-btn right icon dark @click="(imagedata = null), (dialog = false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="text-h5 grey lighten-2"> Document </v-card-title>

        <!-- <v-card-text v-if="doc.split('.').pop() == 'pdf'">
          <iframe
            v-if="doc"
            :src="baseURL + '/' + doc"
            height="700px"
            width="900px"
            contain
          >
          </iframe>
        </v-card-text> -->
        <v-card-text v-if="imagedata">
          <v-img :src="mediaURL + imagedata"></v-img>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            small
            color="red"
            text
            @click="(dialog = false), (imagedata = null)"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      imagedata: null,
      deleteDialog: false,
      deleteId: null,
      editId: null,
      editDialog: false,
      Editdivision: "",
      showsnackbar: false,
      msg: "",
      appLoading: false,
      ServerError: false,
      myDialog: false,
      division: "",
      allData: [],
      documentFile1: null,
      docId: null,
      doc: new FormData(),
    };
  },

  mounted() {
    this.getData();
  },

  methods: {
    addDocument() {
      this.appLoading = true;
      this.doc.append("id", this.docId);
      this.doc.append("kmlFile", this.documentFile1);

      axios({
        url: "/upload/kml/file",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.doc,
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.myDialog = false;
            this.editDialog = false;
            this.getData();
          }
          // this.appLoading = false;
          // this.documentID = response.data.data._id;
          // console.log();
          // if (this.documentID != null) {
          //   this.docsupload();
          // } else {
          //   alert("document updated");
          // }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    browseCover(event) {
      var got = event.target.files[0];
      this.documentFile1 = got;
      console.log("got", got);

      // if (got.length < 5) {
      //   for (let i = 0; i < got.length; i++) {
      //     this.documentFile1.push(got[i]);
      //   }
      //   console.log("file isss", this.documentFile1);
      // } else {
      //   this.msg = "Maximum 3 can be uploaded";
      //   this.showSnackBar = true;
      // }
    },

    deleteDivision() {
      axios({
        url: "/delete/division",
        method: "post",
        data: {
          id: this.deleteId,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.deleteDialog = false;
            this.getData();
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    EditDivisions() {
      axios({
        url: "/edit/division",
        method: "post",
        data: {
          id: this.editId,
          name: this.Editdivision,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {

           if (this.documentFile1 != null) {
             this.docId = this.editId;
              this.addDocument();
            }
 

            else
            {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.editDialog = false;
            this.getData();
            }
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/view/all/divisions",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.allData = response.data.data;
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    addDivision() {
      axios({
        url: "/add/division",
        method: "post",
        data: {
          divisionName: this.division,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.docId = response.data.data._id;
            if (this.documentFile1 != null) {
              this.addDocument();
            }
             else {
              this.showsnackbar = true;
              this.msg = response.data.msg;
              this.myDialog = false;
              this.getData();
            }
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
